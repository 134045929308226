const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365; // 1000ms * 60secs * 60mins * 24hrs * 365 days

export const dateDifInYears = (firstDate: Date, secondDate: Date) => (firstDate.getTime() - secondDate.getTime()) / YEAR_IN_MS;

export function calculateTenYearsAndDaysSince(date: Date): boolean {
	const closeOfEscrowDate = new Date(date);
	const now = new Date();
	const timeSpan = Math.abs(dateDifInYears(now, closeOfEscrowDate));
	const isTenYearsPostClose = timeSpan > 10; // 10 years and 1 day or more

	return  isTenYearsPostClose;
}

export function getOneYearAndDayPostCloseDate(date: Date): string | null {
	const closeOfEscrowDate = new Date(date);
	const now = new Date();

	// Check if the current year is a leap year
	const coeYear = closeOfEscrowDate.getFullYear();
	const isCoeYearLeap = (coeYear % 4 === 0 && coeYear % 100 !== 0) || coeYear % 400 === 0;

	// Calculate the date one year and one day post-close
	const yearAndDay = isCoeYearLeap ? 367 : 366;
	closeOfEscrowDate.setDate(closeOfEscrowDate.getDate() + yearAndDay);

	if (now.getTime() > closeOfEscrowDate.getTime()) {
		return null;
	}

	const month = String(closeOfEscrowDate.getMonth() + 1).padStart(2, '0');
	const day = String(closeOfEscrowDate.getDate()).padStart(2, '0');
	const year = String(closeOfEscrowDate.getFullYear()).slice(-2);

	const yearAndDayPostCloseDateStr = `${month}/${day}/${year}`;

	return yearAndDayPostCloseDateStr;
}