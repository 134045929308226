<div class="cp-media-grid">
	<div *ngIf="media.length === 1" class="cp-one-image-row">
			<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[0].cldPublicId, fileExtension: media[0].fileExtension }"></ng-container>
	</div>
	<div *ngIf="media.length === 2" class="cp-two-image-row">
		<div *ngFor="let mediaItem of media" class="cp-two-image-col">
			<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: mediaItem.cldPublicId, fileExtension: media[0].fileExtension }"></ng-container>
		</div>
	</div>
	<ng-container *cpIfViewSize="['desktop', 'tablet']">
		<div *ngIf="media.length > 2" class="cp-three-image-row">
			<!-- First image is its own column, displayed larger -->
			<div class="cp-three-image-col">
				<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[0].cldPublicId, fileExtension: media[0].fileExtension }"></ng-container>
			</div>
			<!-- Next two images are on a separate column -->
			<div class="cp-three-image-col">
				<div class="cp-three-image-wrapper">
					<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[1].cldPublicId, fileExtension: media[1].fileExtension }"></ng-container>
				</div>
				<div class="cp-three-image-wrapper" aria-hidden="true" [ngClass]="{'cp-more-images' : media.length > 3 }" (click)="openLightbox(media[2].cldPublicId)">
					<!-- Dynamically show a "see more" element or the last image, depending how many remain -->
					<div *ngIf="media.length > 3" class="cp-more-images-label">
						<span>+{{media.length - 2}}</span>
					</div>
					<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[2].cldPublicId, fileExtension: media[2].fileExtension }"></ng-container>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *cpIfViewSize="'mobile'">
		<div *ngIf="media.length > 2" class="cp-four-image-row">
			<div class="cp-four-image-wrapper">
				<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[0].cldPublicId, fileExtension: media[0].fileExtension }"></ng-container>
			</div>
			<div class="cp-four-image-wrapper">
				<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[1].cldPublicId, fileExtension: media[1].fileExtension }"></ng-container>
			</div>
			<div class="cp-four-image-wrapper">
				<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[2].cldPublicId, fileExtension: media[2].fileExtension }"></ng-container>
			</div>
			<div *ngIf="media.length === 3" class="cp-four-image-wrapper"></div> <!-- Empty div to force grid layout -->
			<div *ngIf="media.length === 4" class="cp-four-image-wrapper">
				<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[3].cldPublicId, fileExtension: media[3].fileExtension }"></ng-container>
			</div>
			<ng-container *ngIf="media.length > 4">
				<div class="cp-four-image-wrapper cp-more-images" aria-hidden="true" (click)="openLightbox(media[3].cldPublicId)">
					<!-- Dynamically show a "see more" element or the last image, depending how many remain -->
					<div class="cp-more-images-label">
						<span>+{{media.length - 3}}</span>
					</div>
					<ng-container [ngTemplateOutlet]="mediaTemplate" [ngTemplateOutletContext]="{ publicId: media[3].cldPublicId, fileExtension: media[3].fileExtension, isOverflow: true }"></ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>

<!-- Image/Video Template -->
<ng-template #mediaTemplate let-publicId="publicId" let-fileExtension="fileExtension" let-isOverflow="isOverflow">
	<div
		class="cp-media-template"
		(click)="openLightbox(publicId)"
		(keypress)="keyPressHandler($event, openLightbox(publicId))"
		role="button"
		tabindex="0">
		<cp-cloudinary-media
			[publicId]="publicId"
			[fileExtension]="fileExtension"
			[disablePlayback]="true"
			[isClickable]="true"
			[isOverflow]="isOverflow"
			altText="Click to view image">
		</cp-cloudinary-media>
	</div>
</ng-template>