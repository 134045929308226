<mat-card class="cp-text-card">
	<mat-card-header>
		<mat-card-title>
			<h2 class="cp-text-card-header" aria-label="Text Card Header">{{header}}</h2>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content class="cp-text-card-content">
		<ng-container *ngTemplateOutlet="template"></ng-container>
	</mat-card-content>
</mat-card>
