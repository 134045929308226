import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class IdleService {
	public modalEvent: EventEmitter<unknown> = new EventEmitter();

	emitModalEvent(event: unknown) {
		this.modalEvent.emit(event);
	}
}
