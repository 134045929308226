import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

import { Observable, ReplaySubject, Subject, filter, map, share, take } from 'rxjs';

import { IEnvironment } from 'src/environments/environment.model';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import { YourHomeFacade } from 'src/app/modules/ngrx-store/your-home/your-home.facade';

import { AUTH_ROLES, AUTH_TYPES } from 'src/app/modules/shared/constants/auth-types';
import { CORE_ROUTES, NAVIGATION_ROUTES, ROUTES } from 'src/app/modules/shared/constants/route-paths';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Injectable({
	providedIn: 'root'
})
export class AuthService extends UnsubscribeOnDestroy {
	private loggedInSubject$: Subject<boolean> = new ReplaySubject<boolean>(1);
	private settings!: IEnvironment;

	constructor(
		private router: Router,
		private osvc: OAuthService,
		private brandService: BrandService,
		private settingsService: SettingsService,
		private appFacade: AppFacade,
		private yourHomeFacade: YourHomeFacade
	) {
		super();

		this.settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			this.settings = settings;
			this.setProvider();
		});

		// Event handler that ensures the url route and params are saved when we get redirected back from sso with a token
		this.osvc.events.pipe(
			filter(event => event.type === 'token_received'), // Only pass through the 'token_received' event
			take(1) // Take the first 'token_received' event and then complete
		).subscribe(event => {
			const isExternal = sessionStorage.getItem('provider') === AUTH_TYPES.B2C;

			if (event && this.osvc.state) {
				const redirect_uri = decodeURIComponent(this.osvc.state);
				let salesAgreementId = 0;

				// Load home details for non-external users
				// External users redirected from MyAccount doesn't set state on ovsc, hence moving it outside the if block
				if (!isExternal) {
					salesAgreementId = Number(redirect_uri.split('/')[redirect_uri.indexOf(CORE_ROUTES.Preview) + 1]) ?? undefined;
					this.yourHomeFacade.loadHomeDetails(salesAgreementId);
				}

				if (redirect_uri && redirect_uri !== '/') {
					// Unable to use internalUrlPipe because the auth redirect is pointing to "/" instead of "/preview"
					this.router.navigateByUrl(isExternal ? redirect_uri : `/${CORE_ROUTES.Preview}/${salesAgreementId}/${NAVIGATION_ROUTES.Dashboard}`);
				}
			}

			if (isExternal) {
				// Load home details for external users
				this.yourHomeFacade.loadHomeDetails();

				// Remove router query params after redirect from MyAccount
				this.router.navigate([], {
					queryParams: {},
					queryParamsHandling: 'merge'
				});
			}

			const identityClaims = this.osvc.getIdentityClaims();

			// Gets the user's account info from the claims
			// and sets it in the store
			const userInfo = {
				accountId: isExternal ? identityClaims['tid'] : identityClaims['oid'], // tid is used for b2c, oid is used for Azure
				userName: isExternal ? identityClaims['email'] : identityClaims['preferred_username'], // sub is used for b2c, preferred_username is used for Azure
				firstName: isExternal ? identityClaims['given_name'] : 'Preview', // given_name is used for b2c, 'Preview' is used for Azure
				lastName: isExternal ? identityClaims['family_name'] : '', // family_name is used for b2c, empty is used for Azure
				// Strip all non-numerical characters from the phone number to keep formatting consistent
				phoneNumber: isExternal ? identityClaims['telephoneNumber'].replace(/\D/g,'') : '', // telephoneNumber is used for b2c, empty is used for Azure
				isExternal: isExternal,
				isOutsideUs: isExternal ? (!identityClaims['postalCode'] || identityClaims['postalCode'] === 'n/a') : false // preview users default to inside the us
			};
			this.appFacade.setUserInfo(userInfo);
		});
	}

	get isLoggedIn(): Observable<boolean> {
		return this.loggedInSubject$;
	}

	logout(): void {
		// Removes all tokens and navigates user to the logout url if configured
		this.osvc.logOut();
	}

	configure(config: AuthConfig): Observable<boolean> {
		// Auth config has the generic auth information that can be used for any brand
		const authConfig = config;

		if (sessionStorage.getItem('provider') === AUTH_TYPES.B2C) {
			authConfig.issuer = config.issuer?.replace('{{brand}}', this.brandService.brand.secondLevelDomain ?? '');
		}

		authConfig.redirectUri = window.location.origin;

		this.osvc.configure(authConfig);
		this.osvc.setStorage(sessionStorage);
		// Without specifying just id_token, this will try to fetch irrelevant access_token every second
		this.osvc.setupAutomaticSilentRefresh(undefined, 'id_token');

		if (this.settings.requireAuth) {
			// Loads information from the b2c issuer and tries to login
			this.osvc.loadDiscoveryDocumentAndTryLogin().then(() => {
				// Sets the loggedInSubject so that if the user already has an auth cookie it will not try to login again
				this.loggedInSubject$.next(this.osvc.hasValidAccessToken() && this.osvc.hasValidIdToken());
			});
		} else {
			this.loggedInSubject$.next(true);
		}

		return this.loggedInSubject$.pipe(share());
	}

	canActivate() {
		return this.isLoggedIn.pipe(
			map(loggedIn => {
				if (!loggedIn) {
					// Saves url state so that we are redirected back to the same page after auth
					const request_uri = window.location.pathname + window.location.search;
					const additionalState = (request_uri !== '/' ? request_uri : undefined);

					this.osvc.initLoginFlow(additionalState);
					return false;
				}

				// Checks that Azure users have the correct role
				if (sessionStorage.getItem('provider') === AUTH_TYPES.AZURE) {
					// Getting the user roles from the token every time becuase storing them
					// in a variable causes the user to be taken to the error page on refresh
					const userRoles = this.osvc.getIdentityClaims()['roles'];
					// Allow access if the user has CustomerPortalPreview in their claims
					if (userRoles && userRoles.includes(AUTH_ROLES.PREVIEW)) {
						return true;
					}

					// Navigate to the error page with a 403 unauthorized if they do not have the role
					this.router.navigate([ROUTES.Error], { state: { data: 403 } });
				}

				return true;
			}));
	}

	setProvider(): void {
		const currProvider = sessionStorage.getItem('provider');
		if (currProvider && window.location.search.indexOf('?code=') == 0) {
			this.setAuthConfig(currProvider);
		} else if (window.location.pathname.includes(CORE_ROUTES.Preview)) {
			this.setAuthConfig(AUTH_TYPES.AZURE);
		} else {
			this.setAuthConfig(AUTH_TYPES.B2C);
		}
	}

	setAuthConfig(provider: string): void {
		sessionStorage.setItem('provider', provider);
		this.configure(this.settings.authConfigs[provider]);
	}
}