import { ActionCreator, ReducerTypes, on } from '@ngrx/store';

import * as AppActions from 'src/app/modules/ngrx-store/app/app.actions';

import { STANDARD_DISCLAIMER } from 'src/app/modules/shared/constants/disclaimers';
import { UserInfo } from 'src/app/modules/shared/types/auth';

export interface State {
	/*
	 * customerName
	 */
	customerName?: { firstName: string, lastName: string } | null,
	disclaimers: string[],
	/*
	 * userInfo
	 */
	userInfo?: UserInfo | null,
	/*
	 * errorCode
	 */
	errorCode?: number | null
}

export const initialState: State = {
	disclaimers: [STANDARD_DISCLAIMER],
}

export function getAppOns<T extends State>(): ReducerTypes<T, ActionCreator[]>[] {
	return [
		/*
		 * setUserInfo
		 */
		on(AppActions.setUserInfo, (state, { userInfo: userInfo }) => ({
			...state,
			userInfo: userInfo,
		})),
		/*
		 * setDisclaimers
		 */
		on(AppActions.setDisclaimers, (state, { disclaimers: disclaimers }) => ({
			...state,
			disclaimers: disclaimers
		})),
		/*
		 * setErrorCode
		 */
		on(AppActions.setErrorCode, (state, { errorCode: errorCode }) => ({
			...state,
			errorCode: errorCode
		})),
	];
}