<ng-container [ngSwitch]="errorCode">
	<div *ngSwitchCase="ERROR_CODES.NOT_FOUND" class="cp-flex-container">
		<div class="cp-flex-row">
			<div class="cp-flex-col">
				<h1 aria-label="Page Header">Uh oh! Page not found</h1>
			</div>
		</div>
		<div class="cp-flex-row">
			<div class="cp-flex-col">
				<p class="cp-error-text">
					We're sorry we couldn't find that page for you, but since we're all about having 
					a good foundation we've laid out some helpful links to get you back on track.
				</p>
			</div>
		</div>
		<div class="cp-flex-row">
			<span [routerLink]="routes.Dashboard | internalUrl">View Your Dashboard</span>
		</div>
		<div class="cp-flex-row">
			<p class="cp-type-14">Error code: {{errorCode}}</p>
		</div>
	</div>
	<ng-container *ngSwitchDefault>
		<cp-header class="cp-main-header" [isMyAccountEnabled]="false"></cp-header>
		<div class="cp-flex-container">
			<div class="cp-flex-row">
				<div class="cp-flex-col">
					<h1 aria-label="Page Header">{{errorHeader}}</h1>
				</div>
			</div>
			<div class="cp-flex-row">
				<div class="cp-flex-col cp-error-text-container">
					<p class="cp-error-text" [innerHTML]="errorText"></p>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>
