import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { JobService } from 'src/app/modules/core/services/job.service';
import { SalesService } from 'src/app/modules/core/services/sales.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';

import { AUTH_TYPES } from 'src/app/modules/shared/constants/auth-types';
import { ERROR_CODES } from 'src/app/modules/shared/constants/error-codes';
import { ROUTES } from 'src/app/modules/shared/constants/route-paths';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Injectable()
export class YourHomeEffects extends UnsubscribeOnDestroy {

	myAccountUrl: string | undefined;

	constructor(
		private actions$: Actions,
		private jobService: JobService,
		private salesService: SalesService,
		private router: Router,
		private brandService: BrandService, 
		private settingsService: SettingsService,
		private appFacade: AppFacade) {
		super();
		this.settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			const brand = this.brandService.getBrand();
			this.myAccountUrl = settings.brandConfigs[brand.name].myAccountUrl;
		});
	}

	// Retrieves the Home Details on the Your Home page.
	loadHomeDetails$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetails),
		switchMap(({ salesAgreementId }) => {
			if (salesAgreementId) {
				return this.salesService.getHomeDetails(salesAgreementId).pipe(
					map((homeDetails) => YourHomeActions.loadHomeDetailsSuccess({ homeDetails })),
					catchError((err) =>
						of(
							YourHomeActions.loadHomeDetailsFailure({
								error: err
							})
						))
				)			
			} else {
				return this.salesService.getHomeDetailsViaSso().pipe(
					map((homeDetails) => YourHomeActions.loadHomeDetailsSuccess({ homeDetails })),
					catchError((err) =>
						of(
							YourHomeActions.loadHomeDetailsFailure({
								error: err
							})
						)
					)
				)
			}
		})
	));

	// When loading Home Details fails, display an alert notifying the user of the error
	loadHomeDetailsFailure$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsFailure),
		tap((error) => {
			// If a custom error code was returned, use that. Otherwise default to the error status.
			const errorCode = error.error.error?.errorCode ? error.error.error.errorCode : error.error.status;
			this.appFacade.setErrorCode(errorCode);

			switch (errorCode) {
				case ERROR_CODES.FORBIDDEN:
				case ERROR_CODES.UNAUTHORIZED:
					// User is logged in with Azure but is not authorized
					if (sessionStorage.getItem('provider') === AUTH_TYPES.AZURE) {
						this.router.navigate([ROUTES.Error], { skipLocationChange: true });
					}
					// Portal not enabled
					if (this.myAccountUrl) {
						window.location.href = this.myAccountUrl;
					}
					// TODO what if myAccountUrl is null somehow?
					break;
				default:
					this.router.navigate([ROUTES.Error], { skipLocationChange: true });
					break;
			}
		})
	),
	{ dispatch: false }
	);

	// Retrieves the Job Options (after Home Details have been successfully retrieved).
	loadJobOptions$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsSuccess),
		switchMap(({ homeDetails }) =>
			this.jobService.getJobOptions(homeDetails.jobId).pipe(
				map((jobOptions) => YourHomeActions.loadJobOptionsSuccess({ jobOptions })),
				catchError((err) =>
					of(
						YourHomeActions.loadJobOptionsFailure({
							error: err
						})
					))
			)
		)
	));
}