import { ComponentFixture } from '@angular/core/testing';

import { axe } from 'jest-axe';

// Checks if the current device uses touch controls
export function isTouchDevice(): boolean {
	return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.maxTouchPoints > 0);
}

export function keyPressHandler($event: KeyboardEvent, method: void): void {
	// Used to stop auto page scrolling when selecting an element with the spacebar
	if ($event.code === 'Space') {
		$event.preventDefault();
	}

	if ($event.code === 'Enter') {
		void (method);
	}
}

export async function checkAccessibilityViolations(fixture: ComponentFixture<unknown>) {
	const results = await axe(fixture.nativeElement);
	expect(results).toHaveNoViolations();
}