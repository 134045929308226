import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as fromRoot from 'src/app/modules/ngrx-store/reducers';
import * as AppActions from 'src/app/modules/ngrx-store/app/app.actions';
import * as AppSelectors from 'src/app/modules/ngrx-store/app/app.selector';

import { UserInfo } from 'src/app/modules/shared/types/auth';
import { STANDARD_DISCLAIMER } from 'src/app/modules/shared/constants/disclaimers';

@Injectable()
export class AppFacade {
	disclaimers$: Observable<string[]> = this.store.pipe(select(AppSelectors.getDisclaimers));
	userInfo$: Observable<UserInfo | null | undefined> = this.store.pipe(select(AppSelectors.getUserInfo));
	errorCode$: Observable<number | null | undefined> = this.store.pipe(select(AppSelectors.getErrorCode));

	constructor(protected store: Store<fromRoot.AppDomainPartialState>) { }

	setUserInfo(userInfo: UserInfo): void {
		this.store.dispatch(AppActions.setUserInfo({ userInfo: userInfo }));
	}

	// Sets disclaimers back to global default
	resetDisclaimers(): void {
		this.store.dispatch(AppActions.setDisclaimers({ disclaimers: [STANDARD_DISCLAIMER] }))
	}

	setDisclaimers(disclaimers: string[]): void {
		this.store.dispatch(AppActions.setDisclaimers({ disclaimers: disclaimers }));
	}

	resetErrorCode(): void {
		this.store.dispatch(AppActions.setErrorCode({ errorCode: null }));
	}

	setErrorCode(errorCode: number): void {
		this.store.dispatch(AppActions.setErrorCode({ errorCode: errorCode }));
	}
}