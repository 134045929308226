import { Component, Input } from '@angular/core';

import { keyPressHandler } from 'src/app/modules/shared/utilities/accessibility.utils';

@Component({
	selector: 'cp-build-version',
	templateUrl: './build-version.component.html',
	styleUrls: ['./build-version.component.scss']
})
export class BuildVersionComponent {
	keyPressHandler = keyPressHandler;

	@Input() branch: string = '';
	@Input() version: string = '';

	// eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
	dismiss(target: any): void {
		target.classList.add('hidden');
	}
}
